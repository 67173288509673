// Query Sizes
$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;

.common_container {
  width: 100%;

  @media screen and (min-width: $screen-sm) {
    padding: 0 5%;
  }

  @media screen and (min-width: $screen-xl) {
    padding: 0 10%;
  }
}

@media screen and (max-width: $screen-md) {
  input {
    font-size: 16px !important;
  }

  input:focus {
    font-size: 16px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.toastStyle {
  font-family: "ManropeRegular";
}

.css-1dozdou .css-l0iinn .css-1v994a0 {
  font-family: "ManropeRegular";
}

.css-10fao8b-MuiPickersCalendarHeader-labelContainer {
  font-family: "ManropeRegular" !important;
}

svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}
