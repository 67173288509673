.popnoti .MuiPopover-paper {
  max-width: 500px;
  min-width: 500px;
}
@media (max-width: 768px) {
  .popnoti .MuiPopover-paper {
    max-width: 250px;
    min-width: 250px;
  }
}
.memubarsubData {
  padding: 0 !important;
  overflow-y: auto;
}

.memubarsubData::-webkit-scrollbar {
  display: none;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  background-color: #f3f0f0;
}
@media (max-device-width: 870px) {
  .mainContainer {
    height: calc(100% - 148px);
  }
}

.mainContainer::-webkit-scrollbar {
  display: none;
}
