.wlcm {
  color: #3598dc;
  text-align: center;
}
.txtInp {
  margin-top: 8px;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.forgotPassword {
  margin-top: 20px !important;
  font-size: 14px !important;
  color: #4573b8;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-end;
}

.btn {
  animation: effect 0.3s infinite;
}
.btn:hover {
  animation: effect 0.4s none;
}

@media (max-device-width: 425px) {
  .mecimg {
    position: unset !important;
    align-self: center !important;
  }
}
@keyframes effect {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  /* 10% {
    transform: translateX(-2px) rotate(-2deg);
  }
  20% {
    transform: translateX(-1.5px) rotate(-1.5deg);
  }
  */
  25% {
    transform: translateX(-0.5px) rotate(-0.5deg);
  }
  /* 30% {
    transform: translateX(-1px) rotate(-1deg);
  }
  40% {
    transform: translateX(-0.5px) rotate(-0.5deg);
  } */
  50% {
    transform: translateX(0px) rotate(0deg);
  }
  /* 60% {
    transform: translateX(0.5px) rotate(0.5deg);
  }
  70% {
    transform: translateX(1px) rotate(1deg);
  }*/
  75% {
    transform: translateX(0.5px) rotate(0.5deg);
  }
  /*
  80% {
    transform: translateX(1.5px) rotate(1.5deg);
  }
  90% {
    transform: translateX(2px) rotate(2deg);
  } */
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}
