* {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

i {
  color: #d3d3d3;
}

.MT60 {
  margin-top: 60px;
  padding: 16px 16px;
}

@font-face {
  font-family: "ManropeRegular";
  src: local("ManropeRegular"),
    url("./Assets/fonts/Manrope-Regular.woff2") format("truetype");
}
