.notification-container {
  box-sizing: border-box;
  position: fixed;
  width: 350px;
  z-index: 100000;
  background-color: #ffffff;
  margin-top: -6px;
  border-radius: 5px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  @media screen and (max-width: 500px) {
    width: 96%;
  }

  @media screen and (max-width: 450px) {
    width: 95%;
  }

  @media screen and (max-width: 350px) {
    width: 94%;
  }
}

.top-right {
  top: 15px;
  right: 15px;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.notification-title {
  font-weight: 600;
  font-family: ManropeRegular !important;
  font-size: 14px;
  letter-spacing: 0.6px;
}

.notification-message {
  font-weight: normal;
  font-family: ManropeRegular !important;
  font-size: 12px;
  letter-spacing: 0.6px;
  color: #696969;
}

.sub-div-for-notification {
  display: flex;
  // width: 100%;
  padding: 9px;
}

.sub-flex-con {
  display: flex;
  flex-direction: column;
}

.notification-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.noti-app-logo {
  width: auto;
  height: 25px;
}

.noti-app-title-text {
  font-weight: normal;
  font-family: ManropeRegular !important;
  font-size: 13px;
  margin-top: -3px;
  margin-left: 5px;
  color: #a9a9a9;
}
